import * as Sentry from "@sentry/nuxt";

Sentry.init({

    // Disabled for localhost
    enabled: useRuntimeConfig().public.sentry.dsn !== "",

    // Put env production/development
    environment: useRuntimeConfig().public.sentry.env,

    dsn: useRuntimeConfig().public.sentry.dsn,

    integrations: [Sentry.replayIntegration()],
    // Tracing
    // We recommend adjusting this value in production, or using a tracesSampler for finer control.
    tracesSampleRate: 1.0, //  Capture 100% of the transactions

    autoSessionTracking: true,

    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});